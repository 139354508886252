@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

button {
  background-color: var(--accent-color);
}

.button-stripe {
  background:  #635bff;
  border-radius: 3px;
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

.stripe-input,
.stripe-select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

.stripe-label {
  display: block;
}

a {
  color: var(--accent-color);
  font-weight: 900;
}

small {
  font-size: 0.6em;
}
/* 
fieldset,
input,
select {
  border: 1px solid #efefef;
} */


#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}
