@import url('https://fonts.googleapis.com/css?family=Inter');

body, button, input, optgroup, select, textarea {
  font-family: Inter,SF Pro Display,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: #F8F9Fb !important;
}

tr:hover {background-color: gainsboro; cursor: pointer;}

.wrappedParagraph {
  white-space: pre-wrap;
  border-style: solid;
  border-width: 2px;
  border-color: gray;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 1%;
}

#imageblock {
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* background-color: rgb(248, 249, 251); */
  /* border-radius: 10px; */
}

:root {
  --jumbotron-padding-y: 3rem;
  /* background-color: rgb(248, 249, 251); */
}



.jumbotron {
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: -20px;  
  background-color: rgb(248, 249, 251);
  background-size: cover;
  /* background-image: url(../public/img/medstudents1.jpeg) ; */
  background-image: none;
}
@media (min-width: 768px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 2);
    background-image: url(../public/img/hero.png) ;
  }
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

/* .jumbotron-heading {
  font-weight: 800;
} */

.jumbotron .container {
  max-width: 40rem;
}

.jumbotron .jumbo-header {
  /* font-size: 50px; */
  color: rgb(33, 37, 41);
  font-weight: 800;
  letter-spacing: -1px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  line-height: 55px;
  margin-bottom: 10px;
}

.jumbotron .jumbo-subheader{ 
  margin: 20px 0px 20px;
    color: rgb(105, 115, 134);
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    max-width: 780px;
}

.bg-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.intros{ 
    color: rgb(105, 115, 134);
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    
}

.dashboard-card-img {
  height: 200px;
  object-fit: cover;

}

.checkbox-lg {
  top: .8rem;
  scale: 1.4;
  margin-right: 0.7rem;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.public-alert {
  font-weight: 600;
}


.sign-in-form {
  width: 370px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #fff;
  padding: 20PX;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px, rgb(0 0 0 / 0%) 0px 0px, rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
}

.spinner {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #fff;
  padding: 20PX;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px, rgb(0 0 0 / 0%) 0px 0px, rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
}

.smallLinks {
  color:rgb(105, 115, 134);
  font-weight: 300;
}
.smallLinks:hover {
  color: rgb(75, 95, 210) !important;
  font-weight: 300;
}

.btn-text {
  color:rgb(33, 37, 41);
  font-weight: 600;
}

.h4-headers {
  color: rgb(79, 86, 107);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.h6-headers {
  color: rgb(33, 37, 41);
    font-size: 24px;
    font-weight: 600;
}

.custom-button-class { 
  font-weight: 500;
}

.paragraphs {
  color: rgb(79, 86, 107);
    font-weight: 400;
    margin-bottom: 15px;
}

.nav-image {
  border-radius: 10px;
}

.navBrandText {
  color: rgb(33, 37, 41);
  font-size: 25px;
  font-weight: 800;
  padding: 10px;
  border-radius: 10px;
  vertical-align: middle;
}

.custom-btn {
  background-color: rgb(83, 106, 233) !important;
  border: #fff !important;
}

.get-started {
  padding: 10px 25px 10px 25px !important;
  font-weight: 600 !important;
  font-size: 16px !important;

}

.custom-btn:hover {
  border-color: rgb(75, 95, 210) !important;
  background-color: rgb(75, 95, 210) !important;
  color: white !important;
}

.purpleAlert {
  background-color: rgb(208, 213, 237) !important;
  border-color: rgb(208, 213, 237) !important;
}

.tinyText {
  font-size: 12px;
}

.navlogo {
  max-width: 40px;
  max-height: 40px;
  border-radius: 5px;
  vertical-align: middle;
}

.paymentsContainer {
  
  width: 60% !important;
}

@media (max-width: 768px) {
  .paymentsContainer {
    width: 100% !important;
  }
}

.no-link-decoration {
  text-decoration: none !important;
}

